import React from 'react'
import EnLayout from '../components/en_layout'
import Header from '../components/header'
import SEO from '../components/seo'
import Container from '../components/mainContent/container'
import Estilos from '../components/mainContent/container.module.css'
import SlideShowAplicaciones from '../components/imageComponents/slideShowAplicaciones'
import ImagenAplicaciones from '../components/imageComponents/imagenAplicaciones'
import ImagenAplicaciones2 from '../components/imageComponents/imagenAplicaciones2'
import ImagenAplicaciones3 from '../components/imageComponents/imagenAplicaciones3'
import ImagenAplicaciones4 from '../components/imageComponents/imagenAplicaciones4'
import ImagenAplicaciones5 from '../components/imageComponents/imagenAplicaciones5'
import ImagenAplicaciones6 from '../components/imageComponents/imagenAplicaciones6'
import ImagenAplicaciones7 from '../components/imageComponents/imagenAplicaciones7'
import Gallery from '../components/imageComponents/galeria'

const IndexPage = () => (
  <EnLayout>
    <SEO title='Applications' />
    <Header siteTitle='APPLICATIONS' />

    <Container>
      <div className='container'>
        <div className='row justify-content-md-center'>
          <div className='col-12 col-md-6 nopadding '>
          <SlideShowAplicaciones />
          </div>
          <div className='col-12 col-md-6 conmargen'>
            <div style={{ "margin": "0.5em" }}>
              <h3 className={Estilos.title} >IDEAL FOR</h3>
              <ul className='aplicaciones tick'>
                <li>Hot Stamping</li>
                <li> Engravings </li>
                <li> Reliefs </li>
                <li> Burns </li>
                <li> Decals </li>
                <li> Transfers </li>
                <li> Holograms </li>
              </ul>
            </div>
          </div>
          <div className='col-12 conmargen nopadding' style={{ "align": "center" }}>
            <div style={{ "margin": "0.5em" }}>
              <h3 className={Estilos.username} style={{ "textAlign": "center", "margin": "1em" }}>
                MATERIALS YOU CAN PRINT</h3>
            </div>
          </div>
          <div className='col-12 col-md-6 conmargen'>
            <div style={{ "margin": "1em" }}>
              <h3 className={Estilos.username}>GRAPHICS</h3>
              <div className='row'>
                <ul className='aplicaciones tick'>
                  <li> Cards </li>
                  <li> Calendars </li>
                  <li> Book covers </li>
                  <li> Diaries </li>
                  <li> Folders </li>
                  <li> Albums </li>
                  <li> Paper or plastic bags </li>
                  <li> Tags </li>
                  <li> Cardboard boxes </li>
                </ul>
              </div>
            </div>
          </div>
          <div className='col-12 col-md-6 nopadding'>
            <ImagenAplicaciones2 />
          </div>
          <div className='col-12 col-md-6 nopadding'>
            <ImagenAplicaciones3 />
          </div>
          <div className='col-12 col-md-6 conmargen'>
            <div style={{ "margin": "1em" }}>
              <h3 className={Estilos.username}>ADVERTISING</h3>
              <div className='row'>
                <ul className='aplicaciones tick'>
                  <li> Pens </li>
                  <li> Wooden pencils </li>
                  <li> Diaries </li>
                  <li> Plastic keychains </li>
                  <li> Agitators </li>
                  <li> Plastic tokens </li>
                </ul>
              </div>
            </div>
          </div>
          <div className='col-12 col-md-6 conmargen'>
            <div style={{ "margin": "1em" }}>
              <h3 className={Estilos.username}>PLASTIC INDUSTRY</h3>
              <div className='row'>
                <ul className='aplicaciones tick'>
                  <li>Covers printing and decoration </li>
                  <li> Packaging </li>
                  <li> Hangers </li>
                  <li> Small boxes </li>
                </ul>
              </div>
            </div>
          </div>
          <div className='col-12 col-md-6 nopadding'>
            <ImagenAplicaciones4 />
          </div>
          <div className='col-12 col-md-6 nopadding'>
            <ImagenAplicaciones5 />
          </div>
          <div className='col-12 col-md-6 conmargen'>
            <div style={{ "margin": "1em" }}>
              <h3 className={Estilos.username}>LEATHER INDUSTRY</h3>
              <div className='row'>
                <ul className='aplicaciones tick'>
                  <li> Belts </li>
                  <li> Wallets </li>
                  <li> Bags </li>
                  <li> Shoes </li>
                </ul>
              </div>
            </div>
          </div>
          <div className='col-12 col-md-6 conmargen'>
            <div style={{ "margin": "1em" }}>
              <h3 className={Estilos.username}>WOOD INDUSTRY</h3>
              <div className='row'>
                <ul className='aplicaciones tick'>
                  <li> Hangers </li>
                  <li> Pallets </li>
                  <li> Multi-application handles </li>
                </ul>
              </div>
            </div>
          </div>
          <div className='col-12 col-md-6 nopadding'>
            <ImagenAplicaciones6 />
          </div>
          <div className='col-12 col-md-6 nopadding'>
            <ImagenAplicaciones7 />
          </div>
          <div className='col-12 col-md-6 conmargen'>
            <div style={{ "margin": "1em" }}>
              <h3 className={Estilos.username}>TEXTILE INDUSTRY</h3>
              <div className='row'>
                <ul className='aplicaciones tick'>
                  <li> Jewelry bags </li>
                  <li> Composition tags </li>
                  <li> Gift ribbons </li>
                </ul>
              </div>
            </div>
          </div>
          
        </div>

        <div className='col-12  conmargen text-center'   style={{ padding: '2em' }} > <h3>MORE</h3>  </div>
      </div>

      <Gallery />
    </Container>
  </EnLayout>
)

export default IndexPage
